.App {
  background-image: url('https://unsplash.com/photos/IhWRrZx4-kk/download?ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNzE1MTY2NTkzfA&force=true');
  background-repeat: no-repeat;
  background-size: cover;
}

.centered-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-with-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 20px;
  border-radius:16px;
  width: 80%;
  max-width: 800px;
}

.submit-button {
  text-align: right;
}