.Login {
    background-image: url('https://unsplash.com/photos/IhWRrZx4-kk/download?ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNzE1MTY2NTkzfA&force=true');
    background-repeat: no-repeat;
    background-size: cover;
}

.centered-form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-with-shadow {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    padding: 20px;
    border-radius:16px;
    width: 60%;
    max-width: 400px;
}



  
  .form-header {
    font-size: 24px;
    font-family: 'Arial', sans-serif;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .input-field {
    border-radius: 5px;
    padding: 10px;
  }
  
  .submit-button {
    width: 100%;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    border: none;
    color: white;
  }
  
  .submit-button:hover {
    opacity: 0.9;
  }
  
  .form-with-shadow {
    box-shadow: 0px 4px 6px -1px rgba(0,0,0,0.25);
  }
  
  .submit-button-item {
    display: flex;
    justify-content: center;
  }
  